import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/Firebase';
import { adminRoot, currentUser, servicePath } from 'constants/defaultValues';
import { setCurrentUser, getUserToken, getCurrentUser } from 'helpers/Utils';
import axios from 'axios';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  console.log(
    '🚀 ~ file: saga.js:31 ~ loginWithEmailPasswordAsync ~ password:',
    password
  );
  console.log(
    '🚀 ~ file: saga.js:31 ~ loginWithEmailPasswordAsync ~ email:',
    email
  );
  console.log('apii url', servicePath);
  const user = await axios({
    method: 'post',
    url: `${servicePath}/login`,
    data: { email, password },
  })
    .then((res) => res.data)
    .catch((error) => error);

  return user;
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.token) {
      axios.defaults.headers = {
        Authorization: `Bearer ${loginUser.token}`,
      };
      const item = {
        uid: loginUser.token,
        title: loginUser.user.name,
        ...currentUser,
      };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  {
    const name = 'Test Name';
    console.log('🚀 ~ file: saga.js:85 ~ name:', name);
    await auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => user)
      .catch((error) => error);
    const user = await axios({
      method: 'post',
      url: `${servicePath}/register`,
      data: { email, name, password },
    })
      .then((res) => res.data)
      .catch((error) => error);

    return user;
  };

function* registerWithEmailPassword({ payload }) {
  console.log(
    '🚀 ~ file: saga.js:78 ~ function*registerWithEmailPassword ~ payload:',
    payload
  );
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  const token = getUserToken();
  const currUser = getCurrentUser();
  const fd = new FormData();
  fd.append('fcm_token', token);
  fd.append('id', currUser.id);
  fd.append('type', 0);

  await axios({
    method: 'post',
    url: `${servicePath}/logout`,
    data: fd,
  })
    .then((res) => console.log('Ünsallll', res))
    .catch((error) => error);
  setCurrentUser();
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  const currUser = getCurrentUser();
  axios.defaults.headers = {
    Authorization: `Bearer ${currUser.uid}`,
  };
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
